import React from 'react'
import MainSection from './Main'
import Service from './Service'
import PortFolio from './PortFolio'
import Testimonials from './Testimonials'
import Team from './Team'
import Contact from './Contact'
import CallToAction from './CallToAction'
import About from './About'
import Marque from './Marque'
import ImpPoint from './ImpPoint'
import OurClients from './OurClients'
const index = () => {
  return (
<>
    <MainSection/>
    <About/>
    <Marque/>
    <ImpPoint/>
    <Service/>
    <CallToAction/>
    {/* <PortFolio/> */}
    <OurClients/>
    <Testimonials/>
    <Team/>
    <Contact/>
</>
  )
}

export default index
