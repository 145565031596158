import React, { useState } from 'react';
import { FaTwitter, FaInstagram, FaLinkedinIn, FaAngleRight } from 'react-icons/fa';
import { TiSocialFacebook } from 'react-icons/ti';
import { IoLogoSkype } from 'react-icons/io';
import Swal from 'sweetalert2';
import logo  from '../assets/logo-img.png'
const Footer = () => {


  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

// console.log(email,'op')
  const handleEmailChange = (e) => {
      const inputValue = e.target.value;
      setEmail(inputValue);

      // Validate email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(inputValue)) {
          setEmailError('Vänligen ange e-post');
      } else {
          setEmailError('');
      }
  };

  const handleSubmit = (e) => {
      e.preventDefault();

      if (!emailError && email) {
          setLoading(true); // Show loader

          
          setEmail('');

          // Make HTTP POST request to the PHP file
          fetch('https://development81.shrinkcom.com/smtp_mailsss/send-mail-vocatel.php', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({                  
                  email: email,
                  subject:"News Letter",
              }),
          })
              .then(response => {
                  setLoading(false); // Hide loader

                  if (response.ok) {
                      // Handle successful response
                      return response.json();
                  }
                  throw new Error('Network response was not ok.');
              })
              .then(data => {
                  // Handle data if needed
                  Swal.fire({
                      text: 'Tack för din prenumeration',
                      icon: 'success',
                      confirmButtonText: 'OK',
                      confirmButtonColor: '#ffc451',
                  }).then((result) => {
                      if (result.isConfirmed) {
                          window.location.reload();
                      }
                  });
              })
              .catch(error => {
                  setLoading(false); // Hide loader
                  // Handle error
                  console.error('There was a problem with the fetch operation:', error);
              });

      } else {          
          if (!email) {
              setEmailError('Vänligen ange e-post');
          }
      }
  };
  


  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                {/* <h3 className='font-Raleway'>Vocatel<span>.</span></h3> */}
                <div className='mb-4'>

                <img src={logo} alt="vocatel logo" className='h-[26px] '/>
                </div>
                <p>
                    Södergatan 3<br />
                   211 34 Malmö <br /><br />
                  <strong>Telefon:  </strong> +46 40 93 31 00<br />
                  <strong>E-post:</strong> <a href="mailto:info@vocatel.se" className='text-gray-800'>info@vocatel.se</a><br />
                </p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter "><FaTwitter /></a>
                  <a href="#" className="facebook "><TiSocialFacebook /></a>
                  <a href="#" className="instagram "><FaInstagram /></a>
                  <a href="#" className="skype "><IoLogoSkype /></a>
                  <a href="#" className="linkedin "><FaLinkedinIn /></a>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Användbara länkar</h4>
              <ul>
                <li><FaAngleRight  className='text-brand_color'/> <a href="/">Hem</a></li>
                <li><FaAngleRight  className='text-brand_color'/> <a href="#about">Om oss</a></li>
                <li><FaAngleRight  className='text-brand_color'/> <a href="#services">Tjänster</a></li>
                <li><FaAngleRight  className='text-brand_color'/> <a href="#contact">Kontakta oss</a></li>
            {/*     <li><FaAngleRight  className='text-brand_color'/> <a href="#">Användarvillkor</a></li>
                <li><FaAngleRight  className='text-brand_color'/> <a href="#">Integritetspolicy</a></li> */}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Våra tjänster</h4>
              <ul>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Telemarketing / B2C</a></li>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Företagstelefoni / B2B</a></li>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Eventförsäljning</a></li>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Utbildning</a></li>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Mötesbokning</a></li>
                <li><FaAngleRight  className='text-brand_color '/> <a href="#">Direktförsäljning</a></li>
              </ul> 
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Gå med i vårt nyhetsbrev</h4>
              <p>Genom att gå med i vårt nyhetsbrev kommer du få de senaste nyheterna kring vad vi på Vocatel erbjuder.</p>
              
              <form action="" method="" onSubmit={handleSubmit}>
                <div>

                <input type="email" name="email" className='text-black border rounded-sm' value={email} onChange={handleEmailChange}/>
              
                </div>
                {/* <input type="submit" value="Prenumera " />   */}

                <button type="submit"  disabled={loading} className="align-items-center d-flex gap-1 newContant news-letter">
  {loading ? (
    <div className="spinner-border new-loader text-white" role="status">
      <span className="visually-hidden lerloda">Loading...</span>
    </div>
  ) : (
    ""
  )}
  Prenumera
</button>


          
              </form>
              {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
            </div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>Vocatel</span></strong>. All Rights Reserved
        </div>
       
      </div>
    </footer>
  );
}

export default Footer;
