import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

export default function BasicModal() {
    const [name, setName] = useState('');
    const [business_name, setBusiness_name] = useState('');
    const [phone, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [numberError, setNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNumberChange = (e) => {
        const inputValue = e.target.value;
        setNumber(inputValue);

        // Validate number
        if (isNaN(inputValue)) {
            setNumberError('Ange ett telefonnummer');
        } else {
            setNumberError('');
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(inputValue)) {
            setEmailError('Vänligen ange e-post');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!numberError && !emailError && phone && email) {
            setLoading(true); // Show loader

            setNumber('');
            setEmail('');

            // Make HTTP POST request to the PHP file
            fetch('https://development81.shrinkcom.com/smtp_mailsss/send-mail-vocatel.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    phone: phone,
                    email: email,
                    subject: "Free Job",
                }),
            })
                .then(response => {
                    setLoading(false); // Hide loader

                    if (response.ok) {
                        // Handle successful response
                        return response.json();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(data => {
                    // Handle data if needed
                    Swal.fire({
                        text: 'Tack! Vi återkommer snarast möjligt',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#ffc451',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    setLoading(false); // Hide loader

                    // Handle error
                    console.error('There was a problem with the fetch operation:', error);
                });

        } else {
            if (!phone) {
                setNumberError('Ange ett telefonnummer');
            }
            if (!email) {
                setEmailError('Vänligen ange e-post');
            }
        }
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleBusinessChange = (event) => {
        setBusiness_name(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <button onClick={handleOpen} className='cta-btn'>Lediga jobb</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="w100">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className='flex justify-between items-center mb-4'>
                            <h5 className=' mb-1'>Är du redo att vara en del av Vocatel’s expansionsresa.</h5>
                            <p>< AiOutlineClose onClick={handleClose} style={{ cursor: 'pointer' }} /></p>
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                        <div>
                            <p className='text-sm mb-1'>Vill du sätta en bra grund för din karriär? Att vara en stjärna på försäljning kommer ta dig långt! Vi erbjuder dig kontinuerlig produkt och- säljutbildning.
                            </p>
                            <p className='text-sm mb-1'>Vi söker drivna säljare som ska agera spjutspets via telefon, ingen tidigare erfarenhet krävs. Du ska bara brinna för sälj. I din roll bygger du en relation, förhandlar och går på avslut.
                            </p>
                            <p className='text-sm mb-1'>Hos oss på Vocatel uppmärksammas bra prestationer- både med provision och bonusar! </p>
                            <p className='text-sm m-0'>Vi anställer löpande så tveka inte. Kontakta oss redan idag via formuläret nedan eller maila ert cv till  <a href="mailto:jobb@vocatel.se" className='text-dark'>jobb@vocatel.se</a> </p>
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>



                            {loading && (
                                <div className='position_loder'>
                                    <div className="spinner-border text-brand_color" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}


                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 flex justify-center items-center'>
                                    <input type="text" className="rounded-md shadow-inner shadow-gray-50 outline-none w-full border py-2 px-3" id="fullName" placeholder="Namn" value={name} onChange={handleNameChange} />
                                </div>
                                <div className='mb-3'>
                                    <input type="email" id="email" placeholder="E-post" className="rounded-md shadow-inner shadow-gray-50 outline-none w-full border py-2 px-3" value={email} onChange={handleEmailChange} />
                                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                                </div>
                                <div className='mb-3'>
                                    <input type="number" placeholder="Telefon" value={phone} onChange={handleNumberChange} className='rounded-md shadow-inner shadow-gray-50 w-full border py-2 px-3 outline-none' />
                                    {numberError && <div style={{ color: 'red' }}>{numberError}</div>}
                                </div>
                                <div className=''>
                                    <button type="submit" className="border py-2 px-3 bg-brand_color text-white rounded">Skicka</button>
                                </div>
                            </form>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
