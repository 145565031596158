import React, { useEffect, useState } from 'react'
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
// import { AiOutlineMobile } from "react-icons/ai";
import { IoIosCall } from "react-icons/io";
import AOS from 'aos';
import Swal from 'sweetalert2';
import 'aos/dist/aos.css';
const Contact = () => {
  useEffect(() => {
    AOS.init();
}, []);





const [name, setName] = useState('');
const [phone, setNumber] = useState('');
const [email, setEmail] = useState('');
const [numberError, setNumberError] = useState('');
const [emailError, setEmailError] = useState('');
const [message, setMessage] = useState('');
const [loading, setLoading] = useState(false);

const handleNumberChange = (e) => {
    const inputValue = e.target.value;

    setNumber(inputValue);

    // Validate number
    if (isNaN(inputValue)) {
        setNumberError('Ange ett telefonnummer');
    } else {
        setNumberError('');
    }
};

const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputValue)) {
        setEmailError('Vänligen ange e-post');
    } else {
        setEmailError('');
    }
};



const handleSubmit = (e) => {
    e.preventDefault();

    if (!numberError && !emailError && phone && email) {
        setLoading(true); // Show loader

        setNumber('');
        setEmail('');

        // Make HTTP POST request to the PHP file
        fetch('https://development81.shrinkcom.com/smtp_mailsss/send-mail-vocatel.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: name,
              email: email,
              phone: phone,
              message: message,
              subject: "Contact Us"
            }),
        })
            .then(response => {
                setLoading(false); // Hide loader

                if (response.ok) {
                    // Handle successful response
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                // Handle data if needed
                Swal.fire({
                    text: 'Tack! Vi återkommer snarast möjligt',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#ffc451',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            })
            .catch(error => {
                setLoading(false); // Hide loader

                // Handle error
                console.error('There was a problem with the fetch operation:', error);
            });

    } else {
        if (!phone) {
            setNumberError('Ange ett telefonnummer');
        }
        if (!email) {
            setEmailError('Vänligen ange e-post');
        }
    }
};

const handleNameChange = (event) => {
    setName(event.target.value);
};


const handleMessageChange = (event) => {
    setMessage(event.target.value);
};


  return (
    <section id="contact" className="contact overflow-hidden">
      <div className="container aos-init aos-animate" data-aos="fade-up">

        <div className="section-title">
          <h2>Kontakt</h2>
          <p>Kontakta oss</p>
        </div>

        <div>
          <iframe  style={{border:0, width: "100%" , height: '270px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2253.8114151274235!2d13.0009678!3d55.605295700000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a3fc4ad3149f%3A0x132267bf52033c5a!2sS%C3%B6dergatan%203%2C%20211%2034%20Malm%C3%B6%2C%20Sweden!5e0!3m2!1sen!2sin!4v1717395500838!5m2!1sen!2sin" frameborder="0" allowfullscreen=""></iframe>
          
        </div>

        <div className="row mt-5">

          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className=""> < CiLocationOn /></i>
                <h4>Plats:</h4>
                <p>Södergatan 3 <br /> 211 34 Malmö</p>
              </div>

              <div className="email">
                <i className=""><HiOutlineMail/></i>
                <h4>E-post:</h4>
                <p><a href="mailto:info@vocatel.se" className='text-gray-800'>info@vocatel.se</a></p>
              </div>

              <div className="phone">
                <i className="">< IoIosCall/></i>
                <h4>Telefon: </h4>
                <p>+46 40 93 31 00</p>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
          {/* {loading && (
                                <div className='position_loder'>
                                    <div className="spinner-border text-brand_color" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )} */}
            <form action="/" method="post" onSubmit={handleSubmit} role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control border" id="name" placeholder="Ditt namn" required="" value={name} onChange={handleNameChange}/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control border " name="email" id="email" placeholder="E-post" required="" value={email} onChange={handleEmailChange}/>
                  {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="number" className="form-control border" name="phone" id="subject" placeholder="Telefon" required="" value={phone} onChange={handleNumberChange} />
                 {numberError && <div style={{ color: 'red' }}>{numberError}</div>}
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control border" name="message" rows="5" placeholder="Meddelande" required="" value={message} onChange={handleMessageChange}></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center">
                <button type="submit"  className='flex justify-center mx-auto gap-2 items-center'> { loading ? (
    <div className="spinner-border new-loader text-white" role="status">
      <span className="visually-hidden lerloda">Loading...</span>
    </div>
  ) : (
    ""
  )}
  Skicka meddelande</button>
              
                </div>
            </form>

          </div>

        </div>

      </div>
    </section>
  )
}

export default Contact
