import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import JobModel from './JobModel'

const CallToAction = () => {

  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="cta" className="cta overflow-hidden">
      <div className="container aos-init aos-animate" data-aos="zoom-in">

        <div className="text-center">
          <h3>Lediga jobb</h3>
          <p>Är du en vinnarskalle som älskar försäljning och vill ha möjligheten till att utvecklas och växa inom bolaget? Då är det dig vi söker! Ta en titt bland våra lediga jobb. Vårt professionella team väntar på just dig för att bli en del av vår tillväxtresa!</p>
  
          <p><JobModel/></p>
        </div>
         
      </div>
    </section>
  )
}

export default CallToAction
