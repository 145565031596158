import React from 'react'
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './components/home/index'
import Scroll from './components/ScrollToTop'

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />       
      </Routes>
         < Scroll/>
      <Footer />
    </>

  );
}

export default App;
