import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiPlus } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
const FAQ = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className="mb-4 md:mb-8">
        <p className="text-center text-capitalize text-2xl md:text-3xl lg:text-4xl font-semibold ">
          Har du blivit uppringd av oss?
        </p>
      </div>
      <div className="acoordion-section mt-4 mt-lg-0">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel1" ? (
                <span className="font-semibold text-base">
                  {" "}
                  <AiOutlineClose />
                </span>
              ) : (
                <span className="font-semibold text-xl">
                  <HiOutlinePlus />
                </span>
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <p className="text-black text-base md:text-[17px] font-Raleway font-medium m-0 pe-1">
                Varför möts jag av en automatisk telefonsvarare när jag försöker
                ringa numret som står på min nummerpresentatör?
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="">
            <div>
              <p className="text-left text-sm md:text-base font-roboto tracking-wider text-gray-500">
                När du ser ett obekant telefonnummer på din telefon kanske du
                vill ringa tillbaka för att ta reda på vem som ringt. Gör du det
                blir du upplyst om varför vi sökt dig. Eftersom våra anställda
                nästan alltid är upptagna i telefon är detta ett snabbt sätt att
                ge dig information.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel2" ? (
                <span className="font-semibold text-base">
                  {" "}
                  <AiOutlineClose />
                </span>
              ) : (
                <span className="font-semibold text-xl">
                  <HiOutlinePlus />
                </span>
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <p className="text-black text-base md:text-[17px] font-Raleway font-medium m-0 pe-1">
                Varför har jag fått ett säljsamtal trots att jag är NIX:ad?
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="">
            <div>
              <p className="  text-sm md:text-base font-roboto tracking-wider text-gray-500 text-left">
                Troligtvis har du blivit kontaktad av ett företag som du har,
                eller nyligen har haft, en kundrelation med. Syftet kan vara att
                ta reda på om du är nöjd som kund och om du har några frågor,
                eller att erbjuda dig ytterligare en vara eller tjänst. Kontakta
                oss gärna om du vill veta vem som sökt dig och varför.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel3" ? (
                <span className="font-semibold text-base">
                  {" "}
                  <AiOutlineClose />
                </span>
              ) : (
                <span className="font-semibold text-xl">
                  <HiOutlinePlus />
                </span>
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <p className="text-black text-base md:text-[17px] font-Raleway font-medium m-0 pe-1">
                Hur kan jag spärra min telefon för säljsamtal?
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="">
            <div>
              <p className="text-sm md:text-base font-roboto tracking-wider text-gray-500 text-left">
                Via NIX-Telefon kan privatpersoner spärra sitt fasta
                telefonnummer och/eller sitt mobilnummer för obeställda
                telefonsamtal i marknadsförings, försäljnings- eller
                insamlingssyfte. Att spärra din telefon kostar ingenting mer än
                samtalskostnaden. Ring 077 228 00 00 eller gå in på{" "}
                <a
                  href="https://nixtelefon.org/"
                  className="text-brand_color"
                  target="_blank"
                >
                  nixtelefon.org.
                </a>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel4" ? (
                <span className="font-semibold text-base">
                  {" "}
                  <AiOutlineClose />
                </span>
              ) : (
                <span className="font-semibold text-xl">
                  <HiOutlinePlus />
                </span>
              )
            }
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              <p className="text-black text-base md:text-[17px] font-Raleway font-medium m-0 pe-1">
                Hur kommer jag i kontakt med det företag som jag blev uppringd
                av?
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="">
            <div>
              <p className="m-0 text-sm md:text-base font-roboto tracking-wider text-gray-500 text-left">
                När du ringer upp telefonnumret till den du blivit kontaktad av
                möts du av en telefonsvarare som snabbt ger dig besked om vilket
                företag som sökt dig och varför. Kontaktuppgifterna till oss på
                Vocatel hittar du under Kontakt.
              </p>
              <p className="mt-0 text-sm md:text-base font-roboto tracking-wider text-gray-500 text-left">
              Du kan ringa oss direkt på 010-3302810 eller maila oss på {" "}
                <a href="mailto:ordersupport@vocatel.se" className="text-gray-500">
                ordersupport@vocatel.se
                </a>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
