import React, { useEffect } from 'react'
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../assets/team-1.jpg'
import img2 from '../../assets/team-2.jpg'
import img3 from '../../assets/team-3.jpg'
import img4 from '../../assets/team-4.jpg'
const Team = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (

    <section id="team" className="team overflow-hidden">
      <div className="container aos-init aos-animate" data-aos="fade-up">

        <div className="section-title">
          <h2>Team</h2>
          <p>VÅRT TEAM</p>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img src={img1} className="img-fluid" alt=""/>
                <div className="social">
                  <a href=""><i className="bi bi-twitter"><FaTwitter/></i></a>
                  <a href=""><i className="bi bi-facebook">< FaFacebook /></i></a>
                  <a href=""><i className="bi bi-instagram"><FaInstagram/></i></a>
                  <a href=""><i className="bi bi-linkedin"><FaLinkedin/></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Victor Frisk</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img src={img2} className="img-fluid" alt=""/>
                <div className="social">
                  <a href=""><i className="bi bi-twitter"><FaTwitter/></i></a>
                  <a href=""><i className="bi bi-facebook">< FaFacebook /></i></a>
                  <a href=""><i className="bi bi-instagram"><FaInstagram/></i></a>
                  <a href=""><i className="bi bi-linkedin"><FaLinkedin/></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Sarah Johansson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src={img3} className="img-fluid" alt=""/>
                <div className="social">
                  <a href=""><i className="bi bi-twitter"><FaTwitter/></i></a>
                  <a href=""><i className="bi bi-facebook">< FaFacebook /></i></a>
                  <a href=""><i className="bi bi-instagram"><FaInstagram/></i></a>
                  <a href=""><i className="bi bi-linkedin"><FaLinkedin/></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>William Andersson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
              <div className="member-img">
                <img src={img4} className="img-fluid" alt=""/>
                <div className="social">
                  <a href=""><i className="bi bi-twitter"><FaTwitter/></i></a>
                  <a href=""><i className="bi bi-facebook">< FaFacebook /></i></a>
                  <a href=""><i className="bi bi-instagram"><FaInstagram/></i></a>
                  <a href=""><i className="bi bi-linkedin"><FaLinkedin/></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Amanda Jeppsson</h4>
                <span>Accountant</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Team
