import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import client1 from "../../assets/client-1.png";
import client2 from "../../assets/client-2.png";
import client3 from "../../assets/client-3.png";
import client4 from "../../assets/client-4.png";
import client5 from "../../assets/client-5.png";
import client6 from "../../assets/client-6.png";
import client7 from "../../assets/client-7.png";
import client8 from "../../assets/client-8.png";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import AOS from 'aos';
import 'aos/dist/aos.css';


import { Autoplay,  Navigation } from 'swiper/modules';
export default function App() {

  useEffect(() => {
    AOS.init();
}, []);
  return (
    <>
      <section id="clients" className="clientsd overflow-hidden">
        <div className="container aos-init aos-animate" data-aos="zoom-in">
          <div className="clients-slider swiper">         


<Swiper
  spaceBetween={30}
  centeredSlidms={true}
  autoplay={{
    delay: 2000,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  breakpoints={{
    '@0.00': {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    '@0.75': {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    '@1.00': {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    '@1.50': {
      slidesPerView: 6,
      spaceBetween: 50,
    },
  }}
  // navigation={true}
  modules={[Autoplay, Pagination, Navigation]}
  className="mySwiper"
>
  <SwiperSlide>
    <div className='pb-5 '>
      <img src={client1} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>
    <img src={client2} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>

    <img src={client3} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>

    <img src={client1} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>
    <img src={client2} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>

    <img src={client3} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>

    <img src={client1} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className='pb-5'>

    <img src={client2} alt="" className="h-10 grayscale transition-all duration-300 hover:grayscale-0"/>
    </div>
  </SwiperSlide>
  


     

      
    

 





</Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
