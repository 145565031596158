import React, { useEffect } from 'react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import bgImg from '../../assets/testimonials-bg.jpg'
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../assets/testimonials-1.jpg'

const Testimonials = () => {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <div  className='testimonials overflow-hidden' >
      <div className='container'>
        
      <div className=''>

<Swiper
  spaceBetween={30}
  centeredSlidms={true}
  autoplay={{
    delay: 2500,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  // navigation={true}
  modules={[Autoplay, Pagination, Navigation]}
  className="mySwiper"
>
  <SwiperSlide>
    <div className='w-10/12 py-10 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'>Michael LeBoeuf</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para '> <span className='quote-icon-left'><FaQuoteLeft/></span>A satisfied customer is the best business strategy of all<span className='quote-icon-right'><FaQuoteRight/></span></p>
    
    </div>
  </SwiperSlide>
 
  <SwiperSlide>
    <div className='w-10/12 py-10 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'>Thomas Lovell Beddoes</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para'> <span className='quote-icon-left'><FaQuoteLeft/></span>If there were dreams to sell, what would you buy? <span className='quote-icon-right'><FaQuoteRight/></span></p>
    
    </div>
  </SwiperSlide>
 
  <SwiperSlide>
    <div className='w-10/12 py-10 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'> Unknown</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para'> <span className='quote-icon-left'><FaQuoteLeft/></span>Sell the problem you solve, not the product you have. <span className='quote-icon-right'><FaQuoteRight/></span></p>
    
    </div>
  </SwiperSlide>
 
</Swiper>
</div>
      </div>
      
    </div>
  )
}

export default Testimonials








