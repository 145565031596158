import ScrollToTop from "react-scroll-to-top";

function CoolPage() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
};
  return (
    <div>
      <div className="App">
      <ScrollToTop
        smooth
        viewBox="0 0 24 24"
        svgPath="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z"
      />
      
    </div>
    </div>
  );
}

export default CoolPage

