

import React, { useEffect } from 'react'
import { FaStore } from "react-icons/fa6";
import { MdInsertChartOutlined } from "react-icons/md";
import { RiCalendarTodoLine } from "react-icons/ri";
import { RiPaintBrushLine } from "react-icons/ri";
import { FiDatabase } from "react-icons/fi";
import AOS from 'aos';
import 'aos/dist/aos.css';
import icon1 from '../../assets/ser-1.png'
import icon2 from '../../assets/ser-2.png'
import icon3 from '../../assets/ser-3.png'
import icon4 from '../../assets/ser-4.png'
import icon5 from '../../assets/ser-5.png'
import icon6 from '../../assets/ser-6.png'
const Main = () => {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="hero" className="d-flex align-items-center justify-content-center overflow-hidden">
    <div className="container aos-init aos-animate" data-aos="fade-up">

      <div className="row justify-content-center aos-init aos-animate overflow-hidden" data-aos="fade-up" data-aos-delay="150">
        <div className="col-xl-6 col-lg-8" data-aos="zoom-in">
          <h1 className=''>Trygg försäljning med Vocatel<span>.</span></h1>
          <h2>Vi tar er försäljning till nästa nivå!</h2>
        </div>
      </div>

      <div className="row gy-4 mt-5 justify-content-center  aos-init aos-animate overflow-hidden" data-aos="zoom-in" data-aos-delay="250">
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""> <span className='flex justify-center'><img src={icon1} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Telemarketing / B2C</a></h3>
          </div>
        </div>
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""><span className='flex justify-center'><img src={icon2} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Företagstelefoni / B2B</a></h3>
          </div>
        </div>
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""><span className='flex justify-center'><img src={icon3} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Eventförsäljning</a></h3>
          </div>
        </div>
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""><span className='flex justify-center'><img src={icon4} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Utbildning</a></h3>
          </div>
        </div>
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""><span className='flex justify-center'><img src={icon5} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Mötesbokning</a></h3>
          </div>
        </div>
        <div className="col-xl-3 col-md-4" data-aos="zoom-in">
          <div className="icon-box">
            <i className=""><span className='flex justify-center'><img src={icon6} alt="" className='h-8'/></span></i>
            <h3><a href="#services">Direktförsäljning</a></h3>
          </div>
        </div>
      </div>

    </div>
  </section>
  )
}

export default Main
