import React, { useEffect, useRef, useState } from 'react';
import { BsEmojiSmile, BsJournalRichtext, BsAward } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { CountUp } from 'use-count-up';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaCity } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";

const OurClients = () => {
  const counterRef = useRef(null);
  const [isCountingStarted, setIsCountingStarted] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '0px', // no margin
      threshold: 0.5 // trigger when 50% of the component is in view
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsCountingStarted(true);
        observer.disconnect(); // stop observing once triggered
      }
    }, options);

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect(); // clean up on unmount
      }
    };
  }, []);


  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="counts" className="counts overflow-hidden" ref={counterRef}>
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="row no-gutters">
          <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start aos-init aos-animate" data-aos="fade-right" data-aos-delay="100"></div>
          <div className="col-xl-7 ps-4 ps-lg-5 pe-4 pe-lg-1 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
            <div className="content d-flex flex-column justify-content-center">
              <h3>Vocatel - När du vill göra en förändring på riktigt!</h3>
              <p>
              I en bransch där tekniken ständigt utvecklas och behoven förändras är det avgörande för oss att fokusera på våra kunders bästa intresse.
              </p>
              <div className="row">
                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className=""><BsEmojiSmile /></i>
                    <span className="purecounter">
                      {isCountingStarted && <CountUp isCounting end={1000} duration={3.2} />}+
                    </span>
                    <p><strong>Happy Clients -</strong> En nöjd kund blir vår bästa marknadsförare</p>
                  </div>
                </div>

                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="">< FaCity /></i>
                    <span className="purecounter">
                      {isCountingStarted && <CountUp isCounting end={5} duration={3.2} />}
                    </span>
                    <p><strong>Städer -</strong> Stockholm, Malmö, Örebro, Falkenberg samt Borås</p>
                  </div>
                </div>

                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-clock"><FiClock /></i>
                    <span className="purecounter">
                      {isCountingStarted && <CountUp isCounting end={17} duration={3.2} />}
                    </span>
                    <p><strong>Års Erfarenhet -</strong>  Vår kunskap inom sälj är ovärderlig</p>
                  </div>
                </div>

                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-award">< BsFillPeopleFill  /></i>
                    <span className="purecounter">
                      {isCountingStarted && <CountUp isCounting end={30} duration={3.2} />}+
                    </span>
                    <p><strong> Anställda -</strong> Vi strävar inte efter att vara den största arbetsgivaren, vi vill bli den bästa!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClients;
