import React, { useRef, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {  RxHamburgerMenu } from "react-icons/rx";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import logo from '../assets/logo-img.png'
import mobile_logo from '../assets/mobile-logo.png'

const Navbar = () => {
    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const menuRef = useRef(null);
    const searchRef = useRef(null);

    const toggleMenu = () => {
        setShow(!show);
    };

    const handleClose = () => {
        setShow(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearch(false);
        }
    };

    const handleScroll = () => {
        setShowSearch(false);
    };

    return (
        <> <div className='bg-white '>
 <nav className='container min-h-[66px]'>
                <NavLink to="/" className='text-black font-Raleway text-[32px] font-bold leading-10'>
                 {/* Vocatel<span className='text-brand_color font-bold text-4xl'>.</span> */}
                 <img src={logo} alt="Vocatel logo"  className='h-[26.5px]'/>
                </NavLink>
                <div>
                    <ul id='navbar' className={show ? '#navbar active' : "#navbar"}>
                        <div onClick={toggleMenu} className='cross-icon'>
                            <IoMdClose />
                        </div>
                       
                        {/* <li className='padding-mobile-nav text-center lg:hidden text-black'>Vocatel</li> */}
                        <li onClick={() => { handleClose(); scrollToTop() }} className='text-3xl font-bold padding-mobile-nav text-center lg:hidden text-black font-Raleway flex justify-center' ><NavLink to='/'>{/* Vocatel<span className='text-brand_color font-bold text-4xl'>.</span> */}
                        <img src={mobile_logo} alt="Vocatel logo"  className='h-[14px] d-lg-none mt-[1.5em] mb-2'/></NavLink></li>
                        <li onClick={() => { handleClose(); scrollToTop() }} className='active' ><a href='/'>Hem</a></li>
                        <li onClick={() => { handleClose(); scrollToTop() }} ><a href='#about'>Om oss</a> </li>
                        <li onClick={() => { handleClose(); scrollToTop() }} ><a href='#services'>Tjänster</a> </li>
                       {/*  <li onClick={() => { handleClose(); scrollToTop() }} ><a href='#portfolio'>Portfolio</a> </li> */}
                        <li onClick={() => { handleClose(); scrollToTop() }} ><a href='#team'>Team</a> </li>
                       
                       {/*  <li className='hide-cls_nav'><NavLink  className=' ' style={{display:"flex", alignItems:"center"}} ><span>Dropdown</span><IoMdArrowDropdown className='fs10' /></NavLink>
                            <ul className='sub-menu '   >
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services' >List1</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-cloud'>List2</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-car'> List3</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-document'> List4</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-crm'>List5</NavLink></li>

                            </ul>
                        </li> */}
                        <li onClick={() => { handleClose(); scrollToTop() }}><a href='#contact' >Kontakta oss</a></li>
                  
                       
                    </ul>
                </div>
                <div className='hidden lg:block text-white'>
                    <button className='text-black border-2 py-1.5 px-7 rounded-md border-brand_color hover:bg-brand_color hover:text-white transition-all duration-300 font-medium'>Komma igång</button>
                </div>
                <div id='mobile' onClick={toggleMenu}>
                    <span className='text-black'>
                        <RxHamburgerMenu />
                    </span>
                </div>
            </nav>
        </div>
           
        </>
    );
}

export default Navbar;
