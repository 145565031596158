import React, { useEffect } from 'react'
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import AOS from 'aos';
import 'aos/dist/aos.css';
import aboutImg from '../../assets/about-main.jpg'

const About = () => {
  
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="about" className="about overflow-hidden">
      <div className="container aos-init aos-animate" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
            <img src={aboutImg} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content aos-init" data-aos="fade-right" data-aos-delay="100">
            <h3>Vocatel drivs av ett team av högkompetenta och passionerade medarbetare med djupa kunskaper inom kundhantering och dess tillhörande processer.</h3>
            <p className="fst-italic">
             Med många års erfarenhet i bagaget erbjuder vi allt från professionell support till heltäckande mobila- och it lösningar både för konsument och företag. Vi är dedikerade att skapa mervärde för våra kunder och sänka deras kostnader.
            </p>
            <ul>
              <li className='text-sm'><i className="ri-check-double-line"><span className='font-bold'><IoCheckmarkDoneOutline /></span></i><b>Vi arbetar med värderingar -</b> Vårt beslutsfattande grundar sig på våra värderingar. Det är inte alltid det enklaste att vara den bästa och mest eftertraktade ur både kundens och arbetsgivarens perspektiv. Men vi Vocatel:are tar gärna på oss den utmaningen och gör det med glädje. Det som är bäst för kunden är bäst för oss!</li>
              <li className='text-sm'><i className="ri-check-double-line"><span className='font-bold'><IoCheckmarkDoneOutline /></span></i> <b>Vi erbjuder möjligheter till personlig och professionell utveckling - </b> Vi tror att vi alltid kan lära oss något nytt av varandra. Vi hjälper varandra att utvecklas eftersom livet handlar om ständig inlärning. Vi förstår att misstag är en del av inlärningsprocessen och rättar till dem tillsammans.</li>
              <li className='text-sm'><i className="ri-check-double-line"><span className='font-bold'><IoCheckmarkDoneOutline /></span></i> <b> Vi lyssnar på våra medarbetare - </b>Vi behöver feedback för att kunna utvecklas. Vi mäter vår personals nöjdhet fyra gånger om året för att fortsätta bygga vår framtid tillsammans.
</li>
            </ul>
            <p>
            Vi är stolta över vårt arbete och vår förmåga att skapa långsiktiga och pålitliga affärsrelationer. Genom att konsekvent hålla oss till våra värderingar skapar vi en gedigen affär där du som kund blir vår bästa marknadsförare.
            </p>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About
