import React, { useEffect } from 'react'
import { BiReceipt } from "react-icons/bi";
import { BiCubeAlt } from "react-icons/bi";
import { BiImages } from "react-icons/bi";
import { IoShieldOutline } from "react-icons/io5";
import featureImg  from '../../assets/featuresSection.jpg'
import Accordion from './Accordion'
import AOS from 'aos';
import 'aos/dist/aos.css';

const ImpPoint = () => {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="features" className="features overflow-hidden">
    <div className="container aos-init aos-animate" data-aos="fade-up">
    
      <div className="row ">
        <div className="image-feature col-lg-6 aos-init aos-animate mb-4 mb-lg-0"  data-aos="fade-right">
        {/* <div className="image col-lg-6 aos-init aos-animate" style={{backgroundImage:` url(${featureImg})`}} data-aos="fade-right"> */}
        
            <img src={featureImg} alt="" className='features_image'/>
          
        </div>
        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
        {/*   <div className="icon-box mt-5 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="150">
            <i className="bx bx-receipt"><span><BiReceipt/></span></i>
            <h4>Est labore ad</h4>
            <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
          </div>
          <div className="icon-box mt-5 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="150">
            <i className="bx bx-cube-alt"><span><BiCubeAlt/></span></i>
            <h4>Harum esse qui</h4>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
          </div>
          <div className="icon-box mt-5 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="150">
            <i className="bx bx-images"><span><BiImages/></span></i>
            <h4>Aut occaecati</h4>
            <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
          </div>
          <div className="icon-box mt-5 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="150">
            <i className="bx bx-shield"><span><IoShieldOutline/></span></i>
            <h4>Beatae veritatis</h4>
            <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
          </div> */}
         <Accordion/>
        </div>
      </div>

    </div>
  </section>
  )
}

export default ImpPoint
