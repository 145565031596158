import React, { useEffect } from 'react'
import { FaDribbble } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import { BiTachometer } from "react-icons/bi";
import { BiWorld } from "react-icons/bi";
import { RiSlideshow3Line } from "react-icons/ri";
import { FaArchway } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import icon1 from '../../assets/service-icon1.png'
import icon2 from '../../assets/service-icon2.png'
import icon3 from '../../assets/service-icon3.webp'
import icon4 from '../../assets/service-icon4.png'
import icon5 from '../../assets/service-icon5.png'
import icon6 from '../../assets/service-icon6.png'

const Service = () => {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <section id="services" className="services overflow-hidden">
    <div className="container aos-init aos-animate" data-aos="fade-up">

      <div className="section-title">
        <h2>TJÄNSTER</h2>
        <p>VÅRA TJÄNSTER</p>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon1} alt="" className='h-7'/></span></i></div>
            <h4><a>Telemarketing / B2C</a></h4>
            <p>Vårt fokus ligger på abonnemangstjänster till privatpersoner. Vi tror på långsiktiga samarbeten så därav har vi valt att endast företräda tjänster och produkter som vi själva tror på och som vi med stolthet kan representera.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon2} alt="" className='h-7'/></span></i></div>
            <h4><a>Företagstelefoni / B2B</a></h4>
            <p>Vi är experter på allt som ingår i begreppet mobiltelefoni för företag. Vi har starka samarbeten med flera av dem stora operatörerna och utgår alltid från vad som är bäst för våra kunder.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon3} alt="" className='h-7'/></span></i></div>
            <h4><a>Eventförsäljning</a></h4>
            <p>Vi tror på fysiska möten så för att öka tillväxten för våra uppdragsgivare finns vi på platser där kunderna finns exempelvis på marknader och olika event runt om i landet.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon4} alt="" className='h-7'/></span></i></div>
            {/* <h4>UTBILDNING</h4> */}
            <h4><a>Utbildning</a></h4>
            <p>Vi utbildar idag inom ledarskap, försäljning, kundvård och retorik. Inget bolag är det andra likt därför skräddarsyr våra erfarna utbildare ett upplägg utifrån era behov. </p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon5} alt="" className='h-7'/></span></i></div>
            {/* <h4>MÖTESBOKNING</h4> */}
            <h4><a>Mötesbokning</a></h4>
            <p>Vi skapar kvalitativa möten för er så att ni kan lägga er tid där ni behövs mest, tillsammans med era kunder! Vi tar er från första samtalet till det sista utvärderingsmötet. 
</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box">
            <div className="icon"><i className=""><span ><img src={icon6} alt="" className='h-7'/></span></i></div>
            {/* <h4>DIREKTFÖRSÄLJNING</h4> */}
            <h4><a>Direktförsäljning</a></h4>
            <p>Vi levererar klara och signerade avtal till er. Givetvis med kvalitet och nöjda kunder. Vi har lång och bred erfarenhet av olika branscher inom både B2B och B2C.</p>
          </div>
        </div>

      </div>

    </div>
  </section>
  )
}

export default Service
